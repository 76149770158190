.hero {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: absolute;
}

.form-box {
    width: 23.75rem;
    height: 30rem;
    position: relative;
    margin: 6% auto;
    background-color: #fff;
    padding: 0 46px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.logo {
    margin: 30px auto;
    text-align: center;
}

.logo img {
    width: 70px;
    margin: 0 12px;
}

.input-group {
    margin-bottom: 26px;

    label {
        text-align: left;
        display: block;
        margin-bottom: 5px;
        font-size: 16px;
        color: black;
    }

    input {
        background: #f2f2f5;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        color: #676767;
        font-size: 16px;
        height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: all 0.3s;
        width: 100%;

        &:focus {
            background: #fff;
            border-color: #c4c4c4;
        }

        &::placeholder {
            color: #c4c4c4;
            font-size: 16px;
        }
    }
}

a {
    text-decoration: none;
    color: inherit;
}